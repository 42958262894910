































































































































































































import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import { extend as VeeExtend } from "vee-validate";
import { imageExtension } from "@/core/validation";

/* TODO: После замены ValidationProvider на нативную валидацию,
         перенести в класс компонента и использовать в rules компонента v-file-input
 */
VeeExtend("imageExtension", imageExtension);

type FileDefinition = string | File | { url: string };

@Component({
  methods: {
    imageExtension,
  },
})
export default class ImageInput extends Vue {
  @VModel({ type: [Array, File, String] }) image!:
    | FileDefinition
    | FileDefinition[]
    | null;
  @Prop({ type: String, default: null }) label!: string;
  @Prop({ type: Boolean, default: false }) error!: boolean;
  @Prop({ type: Boolean, default: false }) multiple!: boolean;
  @Prop({ type: Boolean, default: false }) disableDeleting!: boolean;

  public $refs!: {
    input: Vue & { $refs: { input: HTMLInputElement } };
  };

  /**
   * Контролирует snackbar c описанием ошибки валидации
   * @protected
   */
  protected invalidFilesPicked = false;
  protected invalidFilesPickedList: File[] = [];
  protected onImagesChanged(images: File[] | File): void {
    if (Array.isArray(images) && this.multiple) {
      this.invalidFilesPickedList = images.filter(
        (image) => imageExtension(image) !== true
      );
      this.image = images.filter(
        (image) => this.invalidFilesPickedList.indexOf(image) === -1
      );
    } else if (images instanceof File) {
      if (imageExtension(images) !== true) {
        this.invalidFilesPickedList = [images];
      }
      this.image = images;
    }

    if (this.invalidFilesPickedList.length) {
      this.invalidFilesPicked = true;
    }
  }

  /**
   * Кеширует и возвращает список ссылок на картинки выбранных файлов
   * @protected
   */
  protected get thumbs(): string[] {
    const image = this.image;
    if (!image) return [];
    if (typeof image === "string") return [this.getPreview(image)];
    return Array.isArray(image)
      ? image.map(this.getPreview)
      : [this.getPreview(image)];
  }

  private getPreview(file: FileDefinition): string {
    if (file instanceof File) return this.createLocalFileLink(file);
    else if (typeof file === "object" && file.url) return file.url;
    else return file as string; // TS не может определить последний else как string тип, исключив из типа две предыдущих проверки
  }

  /**
   * Возвращает исключительно File экземпляр для v-file-input
   * @protected
   */
  protected get imageFile(): FileDefinition[] | FileDefinition | null {
    return Array.isArray(this.image)
      ? this.image
      : this.image instanceof File
      ? this.image
      : null;
  }

  /**
   * @protected
   */
  protected get isSelected(): boolean {
    return Array.isArray(this.image) ? this.image.length > 0 : !!this.image;
  }

  /**
   * Триггерит v-file-input и открывает окно выбора файлов
   * @protected
   */
  protected openFiles(): void {
    this.$refs.input.$refs.input.click();
  }

  /**
   * Удаляет изобржение из кеша по индексу, если вызвать без аргументов - обнулит весь кеш
   * @param index
   * @protected
   */
  protected deleteImage(index?: number): void {
    alert(index);
    if (Array.isArray(this.image) && index !== undefined) {
      this.image.splice(index, 1);
      this.cachedUrls[index] && URL.revokeObjectURL(this.cachedUrls[index]);
    } else {
      this.image = Array.isArray(this.image) ? [] : null;
    }
  }

  /**
   * Список ссылок на браузерный кеш картинок
   * @private
   */
  private cachedUrls: string[] = [];
  private createLocalFileLink(file: File): string {
    const url = URL.createObjectURL(file);
    this.cachedUrls.push(url);
    return url;
  }
}
